export const MIN_VERSION = '3.2.1';

export const AUTH_TOKEN = {
  version: '2',
  authTokenPolicy: [
    { id: 'autheticate', timeout: 10, credits: 1, behavior: 'logout' },
    { id: 'sign', timeout: 10, credits: 10, behavior: 'logout' },
    { id: 'generate', timeout: 10, credits: 10, behavior: 'logout' }
  ],
  notAfter: 1901491200,
  maxSessionLifetime: 10,
  maxNumberOfSignatures: 20
};

export const KEYSTORES = [
  { id: 'WIN-ENH' },
  { id: 'CSK' },
  { id: 'ETOKEN' },
  { id: 'MTOKEN' },
  { id: 'FF-NSS' },
  { id: 'MACOS' }
];

// Estos valores constantes son utilizados para la intrerfaz v1
export const PROFILES = [];

export const TICKET_POLL_INTERVAL = 500;
