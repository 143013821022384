import * as Desktop from '@app/desktop/desktopSchema';
import {ALISON_DESKTOP_NOT_FOUND} from '@app/core/constants';
import { Client } from '@app/core/client';

export class ApiStub {
  private client: Client;

  /*********************************************
   * Initialization
   *********************************************/

  constructor(url: string) {
    this.client = new Client(url, ALISON_DESKTOP_NOT_FOUND,{ contentType: 'application/x-www-form-urlencoded' });
  }

  async healthCheck(payload: Desktop.V0HealthCheckPayload = {protocol: 'V1'}): Promise<Desktop.V0HealthCheckResponse> {
    const response = await this.client.post('V0/getComponentInfo', payload);
    return response.body;
  }

  /*********************************************
   * Detection (isEnabled, isRunning, enable...)
   *********************************************/

  async isReached(path: any): Promise<Desktop.V0HealthCheckResponse> {
    const response = await this.client.get(path, {});
    //    console.debug('stub isReached -->, response:', response.headers);
    return response.body;
  }

  async fileExists(path: any): Promise<Boolean> {
    const response = await this.client.get(path, {});
    return (response.contentLength > 0);
  }

}
