import { TAD_SERVER_URL } from '@app/core/constants';
import TadInterface from '@app/tad/v1/interfaceStub';

export default class AlisonTadService {
  private stub!: TadInterface;
  constructor(url: string = TAD_SERVER_URL, tenant: string) {
    this.stub = new TadInterface(url, tenant);
  }

  async initialize(args:  any ) {
    return this.stub.initialize(args);
  }

  async certificateList() {
    return this.stub.certificateList();
  }

  async loginTad(args: {
    password: string
  }) {
    return this.stub.loginTad(args);
  }

  async setToken(args: {
    tadToken: string,
    token: string,
    tenant: string
  }) {
    return this.stub.setToken(args);
  }

  async loginTadOtp(args: {
    otp: string
  }) {
    return this.stub.loginTadOtp(args);
  }

  async signatureRequest(args: {
    hashes: [string]
  }) {
    return this.stub.signatureRequest(args);
  }

  async signHash(args: {
    pin: string,
    signatureRequests: [object]
  }) {
    return this.stub.signHash(args);
  }

  async retrieve(args: any) {
    return this.stub.retrieve(args);
  }

}
