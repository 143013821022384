import { ApiStub } from '@app/tad/v1/apiStub';
import WebServerCertificate from '@app/core/webServerCertificate';
import * as Server from '@app/server/serverSchema';

export default class TadInterface {
  private stub: ApiStub;

  async toWebServerCertificate(certificate: Server.Certificate) {
    certificate.TAD = { presence: 'true'};
    certificate.OTP = { presence: 'true'};
    certificate.PIN = { presence: 'true'};
    certificate.profile = {
      keyStoreId: 'TAD',
      name: '20-xxxxxxxxx-9',
      profileName: 'IFDRA Server Cloud',
      friendlyName: '20-xxxxxxxxx-9',
      mechanism: 'tad'
    };
    return new WebServerCertificate(certificate, 'tad');
  }

  constructor(url: string, tenant: string) {
    this.stub = new ApiStub(url, tenant);
  }

  async initialize(args: any) {
    const resp = {session : ''};
    await this.stub.setToken(args);
    const checkResult = await this.stub.checkToken(args.token);
    if (checkResult) {
      Object.assign(resp, { scope: checkResult.scope });
    }
    // TODO: hay que volver a agregar una vez que el backend arregle el tema
    //  if ('seatExists' in result && result.seatExists === false) {
    //    throw new BaseError({ code: SEAT_NOT_FOUND, detail: 'Seat not exists' });
    //  }
    return resp;
  }

  async loginTad(args: any) {
    return this.stub.loginTad(args);
  }

  async setToken(args: any) {
    await this.stub.setToken(args);
  }

  async certificateList() {
    const certificates = await this.stub.certificateList();
    const result = [];
    for (const cert of certificates) {
      result.push(await this.toWebServerCertificate(cert));
    }
    return { certificates: result };
  }

  async loginTadOtp(args: any) {
    return this.stub.loginTadOtp(args);
  }

  async signatureRequest(args: any) {
    return this.stub.signatureRequest(args);
  }

  async signHash(args: any){
    return this.stub.signHash(args);
  }

  async retrieve(args: any) {
    return await this.stub.retrieve(args);
  }
}
