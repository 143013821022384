import { ALISON_JS_UNDEFINED_ERROR } from '@app/core/constants';

const ERROR = {
  code: ALISON_JS_UNDEFINED_ERROR,
  detail: 'Undefined AlisonJS error'
};

export class BaseError extends Error {
  public code: number;
  public detail: string;

  constructor(error?: { code: number; detail?: string }) {
    error = error || ERROR;
    super(`${error.code}: ${error.detail}`);
    this.code = error.code;
    this.detail = error.detail || '';
  }
}
