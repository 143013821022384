
export const ALISON_SERVER_URL = 'https://alisonserver.certisur.com';
export const TAD_SERVER_URL = 'https://alisonserver.certisur.com';

export const ALISON_DESKTOP_URLS = [ 'https://127.0.0.1:8004', 'https://localhost:8004' ];

export const ALISON_DESKTOP_ALL_URLS = [ 'https://127.0.0.1:8004','https://localhost:8004','http://127.0.0.1:8003','http://localhost:8003'];

export const DESKTOP_GEN_OPTIONS = {
  algorithm: 'RSA',
  size: 2048,
  signatureAlgorithm: 'SHA256withRSA'
};

export const DESKTOP_SIGN_OPTIONS = {
  algorithm: 'SHA256withRSA',
  format: 'CAdES',
  extraParams: '',
  textFormat: '',
};

export const SERVER_SIGN_OPTIONS = {
  hashAlgo: '2.16.840.1.101.3.4.2.1',
  signAlgo: '1.2.840.113549.1.1.1'
};

export const ALISON_JS_UNDEFINED_ERROR= 20401;
export const ALISON_DESKTOP_NOT_FOUND= 20404;
export const ALISON_INVALID_LICENSE  = 20405;
export const SEAT_NOT_FOUND          = 20406;
export const ALISON_SERVER_NOT_FOUND = 20408;
export const SERVER_UNAUTHORIZED     = 20413;
export const TAD_SERVER_NOT_FOUND    = 20422;


export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
