import { Client } from '@app/core/client';
import { BaseError } from '@app/core/baseError';
import { SERVER_UNAUTHORIZED, TAD_SERVER_NOT_FOUND } from '@app/core/constants';

const getResult = function(response: any) {
  //console.log(">>AlisonJS:Server:getResult: ", response);
  if (response.code !== 200) {
    if (response.code === 401) {
      throw new BaseError({ code: SERVER_UNAUTHORIZED, detail: 'Unauthorized' });
    }
    // eslint-disable-next-line no-prototype-builtins
    if (response.body.hasOwnProperty('error')) {
      throw new BaseError({
        code: response.body.code,
        detail: response.body.detail
      });
    }
    throw new BaseError({ code: TAD_SERVER_NOT_FOUND, detail: 'TadProvider unreachable.' });
  }
  return response.body;
};

export class ApiStub {
  private client: Client;
  public keyStoreId!: string;

  constructor(public url: string, tenant: string) {
    this.client = new Client(this.url, TAD_SERVER_NOT_FOUND, { contentType: 'application/json', tenant: tenant });
  }

  async setToken(args:{tadToken: string, token: string, tenant: string}) {
    if (args.tadToken) {
      this.client = new Client(this.url, TAD_SERVER_NOT_FOUND,{ contentType: 'application/json;charset=UTF-8', authorization: `Bearer ${args.token}`, authorizationTad: `Bearer ${args.tadToken}`, tenant: args.tenant });
    } else {
      this.client = new Client(this.url, TAD_SERVER_NOT_FOUND,{ contentType: 'application/json;charset=UTF-8', authorization: `Bearer ${args.token}`, tenant: args.tenant });
    }
  }

  async certificateList(): Promise<any[]> {
    const response = await this.client.post('csc/v1/credentials/list-info', {
      'certificates': 'chain',
      'certInfo': true,
      'authInfo': true
    });
    return getResult(response);
  }

  async loginTad(payload: any): Promise<any[]> {
    const response = await this.client.post('tad/login', payload);
    return getResult(response);
  }
  async loginTadOtp(payload: any): Promise<any[]> {
    this.client.setAuthorizationTad(`Bearer ${payload.token}`);
    const response = await this.client.post('tad/login-otp', {otp: payload.otp});
    return getResult(response);
  }

  async signatureRequest(payload: any): Promise<any[]> {
    const response = await this.client.post('tad/signature-request', payload);
    return getResult(response);
  }

  async signHash(payload: any): Promise<any[]> {
    const response = await this.client.post('tad/sign-hashes', payload);
    return getResult(response);
  }

  async retrieve(payload: any): Promise<any> {
    const response = await this.client.get(`retrieve/${payload.uuid}`,null);
    return getResult(response);
  }

  async checkToken(token: string): Promise<any> {
    try {
      this.client.changeContentType('multipart/form-data');
      const response = await this.client.post('oauth/check_token', { token });
      this.client.changeContentType('application/json');
      return getResult(response);
    } catch (e) {
      this.client.changeContentType('application/json');
      throw e;
    }
  }

}
