export { default as AlisonServer } from '@app/server/serverProvider';
export { default as Desktop } from '@app/desktop/desktopProvider';
export { default as AlisonDesktop } from '@app/desktop/desktopProvider';
export { default as TadServer } from '@app/tad/tadProvider';
export { CertificateStatus } from '@app/core/model/CertificateStatus';
export { SignFormat } from '@app/core/model/SignFormat';
import Package from '../package.json';

export default class AlisonJS {

  static version = Package.version;

}
