import { ApiStub } from '@app/server/v1/apiStub';
import * as Server from '@app/server/serverSchema';
import { ISignProvider } from '@app/core/interfaces';
import WebServerCertificate from '@app/core/webServerCertificate';
import { BaseError } from '@app/core/baseError';
import { SEAT_NOT_FOUND } from '@app/core/constants';


export default class ServerInterface implements ISignProvider {
  private stub: ApiStub;

  async toWebServerCertificate(certificate: Server.Certificate) {
    const keys = Object.keys(certificate) as Array<keyof typeof certificate>;
    // follow objects with attribute 'presence', for ex: OTP, PIN, etc.
    for (const key of keys) {
      const value = certificate[key];

      if (typeof value === 'object' && value !== null && 'presence' in value) {
        const mechanism = value as { presence: string };
        if (mechanism.presence === 'true') {
          certificate.profile = {
            keyStoreId: 'CLOUD',
            profileName: 'Alison Server Cloud',
            friendlyName: 'Alison Server Cloud',
            mechanism: key || null
          };
          break;
        }
      }
    }
    return new WebServerCertificate(certificate,'server');
  }

  constructor(url: string) {
    this.stub = new ApiStub(url);
  }

  async initialize(args: any) {
    await this.stub.setToken(args);
    const result = await this.stub.checkToken(args.token);
    if ('seatExists' in result && result.seatExists === false) {
      throw new BaseError({ code: SEAT_NOT_FOUND, detail: 'Seat not exists' });
    }
    return {session: ''};
  }

  // @ts-ignore
  async certificateList() {
    const certificates = await this.stub.certificateList();
    const result = [];
    for (const cert of certificates) {
      result.push(await this.toWebServerCertificate(cert));
    }
    return { certificates: result };
  }

  async certificateInfo(certId: any) {
    const certificate = await this.stub.certificateInfo(certId);
    return this.toWebServerCertificate(certificate);
  }

  async authorizeCredentials(args: any) {
    return this.stub.authorizeCredentials(args);
  }

  async getEmailCode(args: any) {
    return this.stub.getEmailCode(args);
  }

  async getSmsCode(args: any) {
    return this.stub.getSmsCode(args);
  }

  async getSignature(args:any) {
    return await this.stub.signature(args);
  }

}
