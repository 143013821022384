import { Certificate } from '@app/server/serverSchema';
import { CertificateStatus } from './model/CertificateStatus';

import { MONTH } from '@app/core/constants';
import { IWebCertificate } from './interfaces';

interface IFilter {
  validity?: string,
  issuer?: string,
  subject?: string,
  keyStores?: [string?],
  thumbPrint?: string,
  profile?: string,
}
function formatDate(date: string) {
  return [
    date.substring(6, 8),
    MONTH[parseInt(date.substring(4, 6),10)-1],
    date.substring(0, 4)
  ].join('-');
}
const parseDate = (date:string) => [
  parseInt(date.substring(0, 4), 10),
  parseInt(date.substring(4, 6), 10) - 1,
  parseInt(date.substring(6, 8), 10),
  parseInt(date.substring(8, 10), 10),
  parseInt(date.substring(10, 12), 10),
  parseInt(date.substring(12, 14), 10),
];

const dateFromTS = (time:string) => {
  const date = parseDate(time);
  return Date.UTC(date[0], date[1], date[2], date[3], date[4], date[5]) / 1000;
};

function parseDN(dn: any) {
  const dnParts = dn.split(/\s*,\s*/);
  const dnObject = {};
  const ouObject = {};
  let oui = 0;
  dnParts.forEach((part: any) => {
    const match = part.match(/^([^=]+)=(.+)$/);
    if (match) {
      const key = match[1].trim();
      const value = match[2].trim();
      if (key == 'OU') {
        // @ts-ignore
        ouObject['OU' + oui] = value;
        oui = oui + 1;
      } else {
        // @ts-ignore
        dnObject[key] = value;
      }
    }
  });
  return dnObject;
}

export default class WebServerCertificate implements IWebCertificate {
  constructor(protected cert: Certificate, protected provider: string) {}

  get revokedAt() {
    return this.cert.cert.revokedAt;
  }
  get status() {
    return this.cert.cert.status as CertificateStatus;
  }
  get serialNumber() {
    return this.cert.cert.serialNumber;
  }
  get certificates() {
    return this.cert.cert.certificates;
  }
  // @ts-ignore
  get subject() {
    return {
      DN: this.cert.cert.subjectDN,
      ...parseDN(this.cert.cert.subjectDN),
    };
  }
  // @ts-ignore
  get issuer() {
    return {
      DN: this.cert.cert.issuerDN,
      ...parseDN(this.cert.cert.issuerDN),
    };
  }
  get validity() {
    const validTo = dateFromTS(this.cert.cert.validTo);
    const today = Date();
    const expire = validTo - Date.parse(today) / 1000;
    return {
      isExpired: Math.trunc((expire) / 60 / 60 / 24) < 0,
      from: formatDate(this.cert.cert.validFrom),
      left: Math.trunc((expire) / 60 / 60 / 24),
      until: formatDate(this.cert.cert.validTo),
      total: Math.round((dateFromTS(this.cert.cert.validTo) - dateFromTS(this.cert.cert.validFrom)) / (60 * 60 * 24))
    };
  }
  get profile() {
    const parseSubject = parseDN(this.cert.cert.subjectDN);
    // @ts-ignore
    // @ts-ignore
    const cn = parseSubject.CN || parseSubject.O;
    if (this.provider === 'tad')
      return {
        id: 'TAD#>',
        friendlyName: cn,
        name: '20-xxxxxxxx-9',
        keyStoreId: 'TAD',
        keyStoreType: 'TAD',
        keyStoreFriendlyName: 'Firma Digital Remota ARG',
        provider: this.provider
      };
    else
      return {
        id: `CLOUD#>${this.cert.id}`,
        name: this.cert.id,
        keyStoreId: 'CLOUD',
        keyStoreType: 'CLOUD',
        keyStoreFriendlyName: 'Alison Server KeyStore',
        friendlyName: `${this.cert.id} : ${cn}`,
        provider: this.provider
      };
  }

  match(filter: IFilter = {}) {
    let match : any = true;

    match = match && (filter.keyStores === undefined || filter.keyStores.length == 0 || filter.keyStores.includes(this.profile.keyStoreType));

    if (filter.validity !== undefined) {
      const [bw, fw]: any = filter.validity && filter.validity.replace(/\*/g, '3000').split(',');
      match = match && bw < this.validity.left && this.validity.left < fw;
    }

    match = match && (filter.issuer === undefined || this.issuer.DN.includes(filter.issuer));
    match = match && (filter.subject === undefined || this.subject.DN.includes(filter.subject));


    //match = match && (filter.thumbPrint === undefined || this.thumbPrint === filter.thumbPrint);
    match = match && (filter.profile === undefined || this.profile.name === filter.profile);

    return match;
  }
}
