import { Client } from '@app/core/client';
import { BaseError } from '@app/core/baseError';
import { ALISON_SERVER_NOT_FOUND, SERVER_UNAUTHORIZED } from '@app/core/constants';
import * as Server from '@app/server/serverSchema';

const getResult = function(response: any) {
  //console.log(">>AlisonJS:Server:getResult: ", response);
  if (response.code !== 200) {
    if (response.code === 401) {
      throw new BaseError({ code: SERVER_UNAUTHORIZED, detail: 'Unauthorized' });
    }
    // eslint-disable-next-line no-prototype-builtins
    if (response.body.hasOwnProperty('error')) {
      throw new BaseError({
        code: response.body.code,
        detail: response.body.error_description
      });
    }
    throw new BaseError({ code: ALISON_SERVER_NOT_FOUND, detail: 'ServerProvider unreachable.' });
  }
  return response.body;
};

export class ApiStub {
  private client: Client;
  public keyStoreId!: string;

  constructor(public url: string) {
    this.client = new Client(this.url, ALISON_SERVER_NOT_FOUND,{ contentType: 'application/x-www-form-urlencoded' });
  }

  async setToken(args:{token: string, tenant: string}) {
    this.client = new Client(this.url, ALISON_SERVER_NOT_FOUND,{ contentType: 'application/json;charset=UTF-8', authorization: `Bearer ${args.token}`, tenant: args.tenant });
  }

  async certificateList(): Promise<any[]> {
    const response = await this.client.post('csc/v1/credentials/list-info', {
      'certificates': 'chain',
      'certInfo': true,
      'authInfo': true
    });
    return getResult(response);
  }

  async getEmailCode(payload: any): Promise<any[]> {
    const response = await this.client.post(`csc/v1/credentials/${payload.credentialId}/email`, payload);
    return getResult(response);
  }
  async getSmsCode(payload: any): Promise<any[]> {
    const response = await this.client.post(`csc/v1/credentials/${payload.credentialId}/sms`, payload);
    return getResult(response);
  }
  async certificateInfo(payload: Server.CertificateListPayload = {}): Promise<Server.Certificate> {
    const response = await this.client.post('csc/v1/credentials/info', payload);
    return getResult(response);
  }
  async authorizeCredentials(payload: any): Promise<any> {
    const response = await this.client.post('csc/v1/credentials/authorize', payload);
    return getResult(response);
  }
  async signature(payload: any): Promise<any> {
    const response = await this.client.post('csc/v1/signatures/signHash', payload);
    return getResult(response);
  }
  async checkToken(token: string): Promise<any> {
    try {
      this.client.changeContentType('multipart/form-data');
      const response = await this.client.post('oauth/check_token', { token });
      this.client.changeContentType('application/json');
      return getResult(response);
    } catch (e) {
      this.client.changeContentType('application/json');
      throw e;
    }
  }

}
