import * as Desktop from '@app/desktop/desktopSchema';

interface Status {
  code: number;
  critical: boolean;
}
export interface IProfile {
  id: string;
  name: string;
  keyStoreFriendlyName?: string | undefined,
  status?: Status;
  certificateCount?: number;
}
export interface IKeyStore {
  status: Status;
  profiles: IProfile[];
}

export default class KeyStore implements IKeyStore {
  public status: Status;
  public profiles: {
    id: string;
    name: string;
    status: Status;
    certificateCount?: number;
  }[];

  private parseResultStatus({ resultList, resultStatus }: Desktop.ResultStatus): Status {
    return {
      code: (resultList[0] && resultList[0].code) || 0,
      critical: resultStatus == 2
    };
  }

  constructor(keyStoreInfo: any){
    this.status = this.parseResultStatus(keyStoreInfo.status);
    this.profiles = keyStoreInfo.profiles.map((profile: any) => ({
      id: profile.name,
      name: profile.friendlyName,
      status: this.parseResultStatus(profile.status),
      certificateCount: profile.issuedCerts
    }));
  }
}
